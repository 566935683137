/* eslint react/jsx-no-comment-textnodes: 0 */

import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {Helmet} from 'react-helmet'
import Layout from "../components/layout"
import { Trail, animated } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../components/directorList.scss'


class DirectorTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
      currentVideo: '',
      currentVidId: '',
      currentVidThumb: '',
      currentVidCdn: '',
      directorWidth: '',
    }
    this.thumbClick = this.thumbClick.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.checkLength = this.checkLength.bind(this)      
  }

  thumbClick (thumb, id, name, cdn, showVideo, client) {
    this.setState({
      showVideo: showVideo,
      currentVideo: name,
      currentVidId: id,
      currentVidClient: client,
      currentVidThumb: thumb,
      currentVidCdn: cdn

    })
  }
  togglePlay (e){
    e.stopPropagation()
    const vid = document.getElementsByTagName('video')[0]
    vid.paused ? vid.play() : vid.pause()
  }
  componentDidMount(){
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions);
  }
  updateDimensions(){
    let width = document.body.offsetWidth
    let height = document.body.offsetHeight
    let aspect = width / height

    if(width >= 768 && height >= 600 && aspect >= 1)  {
      let parentWidth = document.getElementById('director-wrap').offsetWidth;
      let infoWidth = parentWidth / 3
      this.setState({
        directorWidth: infoWidth,
      })
      // return infoWidth

    } else {
        this.setState({
          directorWidth: '',
        })
    }

  }
  checkLength(videos){
    let exClass = (videos.length > 6) ? 'video-alt' : ''
    return exClass
  }
  
  render(){
    const post = this.props.data.markdownRemark
    const director = post.frontmatter.title
    const directorClass = director.replace(/\s+/g, '-').toLowerCase()
    const itemsTest = post.frontmatter.videos
    // console.log(itemsTest);

    return (
      <Layout>
        <Helmet>
					<title>{post.frontmatter.metaTitle}</title>
					<meta name="description" content={post.excerpt} />
          <meta property="og:url" content={this.props.location.href} />
          <meta property="og:title" content={post.frontmatter.metaTitle} />
          <meta property="og:description" content={post.excerpt} />
          <meta property="og:image" content={this.props.data.site.siteMetadata.siteUrl+post.frontmatter.metaImage.publicURL} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={'picture_north'} />
          <meta name="twitter:title" content={post.frontmatter.metaTitle} />
          <meta name="twitter:description" content={post.excerpt} />
          <meta name="twitter:image" content={this.props.data.site.siteMetadata.siteUrl+post.frontmatter.metaImage.publicURL} />
        </Helmet>
        <div id='director-wrap' className={'director-wrap ' + directorClass }>
          <div onClick={this.thumbClick} className='director-info' >
            <div className='position-wrap' style={{width: this.state.directorWidth}}>
              <div className='profile-wrap' style={{backgroundImage: `url(${post.frontmatter.cover.childImageSharp.resize.src})`}}>
                <img  className='mobile-profile' src={post.frontmatter.cover.childImageSharp.fluid.src} alt={post.frontmatter.title} />
                <div className='content'>
                  <h1>{post.frontmatter.title}</h1>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
              </div>
            </div>
          </div>
          <div className={`grid-wrap ${this.checkLength(itemsTest)}`}>
            <Trail
              native
              from={{opacity:0, y:-50 }}
              to={{ opacity: 1, y: 0 }}
              keys={itemsTest.map(item => item.cdn)}>
              {itemsTest.map( item => ({y, opacity}) => (
                <animated.div
                  className='video-item'
                  onClick={ (e) => this.thumbClick(item.thumb.publicURL, item.id, item.name, item.cdn, true, item.client)}
                  style={{
                    opacity,
                    transform: y.interpolate(y => `translate3d( 0,  0, ${y}px)`),
                    backgroundImage: `url(${item.thumb.childImageSharp.fluid.src})`,
                    backgroundColor: '#111'
                  }}
                  >
                  <Img
                    placeholderStyle={{filter: 'blur(20px)', backgroundColor: '#111', transform: 'scale(1.3)'}}
                    fluid={item.thumb.childImageSharp.fluid}
                  />
                  { item.award  &&
                    <div className="laurel-wrap">
                      { item.award.map(( award, i) => (
                        <div key={`laurel-${i}`}>
                          <img  className="laurel" src={`/${award}.png`} />
                        </div>
                      ))}
                    </div>
                  }
                  <div className="video-info">
                    <h2>{item.name}</h2>
                    <h4>{item.client}</h4>
                  </div>
                  </animated.div>
              ))}
            </Trail>
          </div>
        </div>
        { this.state.showVideo && 
          <div onClick={ (e) => this.thumbClick(e, {showVideo: false})} className='player-overlay'>
            <div className='video-content'>
              <div className='video-header'>
                <h3>{this.state.currentVideo} {this.state.currentVidClient !== '' && (<span className='break'>//</span>)} <span>{this.state.currentVidClient}</span></h3>
                <h3 className='close' onClick={ (e) => this.thumbClick({showVideo: false})}><FontAwesomeIcon icon='times' /></h3>
              </div>
              <div className='video-wrap'>
                <video className='video-element' onClick={(e) => this.togglePlay(e)} autoPlay id={this.state.currentVidId} controlsList="nodownload" poster={this.state.currentVidThumb} controls data-wf-ignore="true" src={this.state.currentVidCdn} />
              </div>
            </div>
          </div>
        }
      </Layout>
    )
  }
}

export default DirectorTemplate

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata{
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        subTitle
        metaTitle
        metaImage {
          publicURL
        }
        cover {
          childImageSharp{
            fluid(maxWidth : 1000){
              ...GatsbyImageSharpFluid_tracedSVG
            }
            resize(width: 2000, height: 2000, cropFocus: CENTER){
              src
            }
          }
          publicURL
        }
        videos {
          name
          client
          id
          cdn
          award
          thumb {
            childImageSharp{
              fluid(maxWidth : 2000){
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
    }
  }
`